import React, { useEffect } from "react";
import "./Products4.scss";
import prd4banner from "../../assets/images/revoluton.bg.jpg";
import headerLine from "../../assets/images/Line 11.png";
import prd4about from "../../assets/images/About.png";
import complience from "../../assets/images/QMScompliance.png";
import document from "../../assets/images/QMSdocument.png";
import audit from "../../assets/images/QMSAudits.png";
import collab from "../../assets/images/QMSCollaboration.png";
import report from "../../assets/images/QMSReporting.png";
import icon1 from "../../assets/images/oneQMSicon1.png";
import icon2 from "../../assets/images/oneQMSicon2.png";
import icon3 from "../../assets/images/oneQMSicon3.png";
import CloudIcon1 from "../../assets/images/CloudAccessibility.png";
import CloudIcon2 from "../../assets/images/CloudSecurity.png";
import CloudIcon3 from "../../assets/images/CloudScalability.png";
import demoImage from "../../assets/images/Discover section.png";
import KF1White from "../../assets/images/KeyFeature1White.png";
import KF2White from "../../assets/images/KeyFeature2White.png";
import KF3White from "../../assets/images/KeyFeature3White.png";
import CloudImg from "../../assets/images/Why_Cloud.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Products4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleRequest = () => {
    navigate("/contact-us");
  };

  const handlepage1 = () => {
    navigate("/quality-management-system");
  };
  const handlepage2 = () => {
    navigate("/document-management-system");
  };
  const handlepage3 = () => {
    navigate("/training-management-system");
  };

  return (
    <div>
      <Helmet>
        <title>
          OneQMS - Comprehensive Quality Management Software Solutions |
          Astakenis Bangalore, India
        </title>
        <meta
          name="description"
          content=" OneQMS by Astakenis offers comprehensive quality management software solutions tailored for pharmaceutical companies, clinical trials, pharmacovigilance, and clinical research. Streamline compliance, enhance efficiency, and ensure safety with our innovative OneQMS platform"
        />
      </Helmet>
      <div className="pt4">
        <div className="pt4_container">
          <div
            className="pt4_container_banner"
            style={{
              backgroundImage: `url(${prd4banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="pt4_container_banner_heading">
              <h1>OneQMS</h1>
            </div>
          </div>
        </div>
        <div className="pt4_pharma">
          <div className="pt4_pharma_container">
            <div className="pt4_pharma_section">
              <div className="pt4_pharma_section_header">
                <h3>OneQMS</h3>
                <div className="pt4_pharma_section_line">
                  <img src={headerLine} alt="line" />
                </div>
              </div>
              <div className="pt4_pharma_section_content">
                <div className="pt4_pharma_section_content_left">
                  <img src={prd4about} alt="ptDms" />
                </div>
                <div className="pt4_pharma_section_content_right">
                  <div className="pt4_pharma_section_content_right_heading">
                    <h3>
                      Revolutionizing Quality Management System (QMS) for Pharma
                      and Clinical Research Organisation (CRO)
                    </h3>
                  </div>
                  <div className="pt4_pharma_section_content_right_data">
                    <p>
                      In the dynamic world of pharmaceuticals and healthcare,
                      maintaining the highest standards of quality assurance and
                      compliance is non-negotiable. Introducing OneQMS software,
                      your all-in-one, cloud-based quality assurance solution
                      designed specifically to streamline and elevate your
                      Quality Management System (QMS) processes in the
                      pharmaceutical industry specifically for pharmaceutical
                      and Clinical Research Organisation (CRO) companies,
                      offering comprehensive services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt4_features">
          <div className="pt4_features_container">
            <div className="pt4_features_container_heading">
              <h3>Key Features of OneQMS</h3>
              <div className="pt4_features_container_heading_line">
                <img src={headerLine} alt="line" />
              </div>
            </div>
            <div className="pt4_features_container_section">
              <div
                className="pt4_features_container_section_card"
                onClick={handlepage1}
              >
                <div className="pt4_features_container_section_card_top">
                  <div className="pt4_features_container_section_card_top_image">
                    <img src={icon1} alt="icon1" className="image1" />
                    <img src={KF1White} alt="KF1White" className="image2" />
                  </div>
                </div>
                <div className="pt4_features_container_section_card_center">
                  <h1>01</h1>
                  <div className="pt4_features_container_section_card_center_line"></div>
                </div>
                <div className="pt4_features_container_section_card_bottom">
                  <div className="pt4_features_container_section_card_bottom_text">
                    <h3>OneQMS Quality</h3>
                  </div>
                </div>
              </div>
              <div
                className="pt4_features_container_section_card"
                onClick={handlepage2}
              >
                <div className="pt4_features_container_section_card_top">
                  <div className="pt4_features_container_section_card_top_image">
                    <img src={icon2} alt="icon2" className="image1" />
                    <img src={KF2White} alt="KF2White" className="image2" />
                  </div>
                </div>
                <div className="pt4_features_container_section_card_center">
                  <h1>02</h1>
                  <div className="pt4_features_container_section_card_center_line"></div>
                </div>
                <div className="pt4_features_container_section_card_bottom">
                  <div className="pt4_features_container_section_card_bottom_text">
                    <h3>OneQMS Document</h3>
                  </div>
                </div>
              </div>
              <div
                className="pt4_features_container_section_card"
                onClick={handlepage3}
              >
                <div className="pt4_features_container_section_card_top">
                  <div className="pt4_features_container_section_card_top_image">
                    <img src={icon3} alt="icon3" className="image1" />
                    <img src={KF3White} alt="KF3White" className="image2" />
                  </div>
                </div>
                <div className="pt4_features_container_section_card_center">
                  <h1>03</h1>
                  <div className="pt4_features_container_section_card_center_line"></div>
                </div>
                <div className="pt4_features_container_section_card_bottom">
                  <div className="pt4_features_container_section_card_bottom_text">
                    <h3>OneQMS Training</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt4_document">
          <div className="pt4_document_container">
            <div className="pt4_document_heading">
              <h2>Why Choose OneQMS</h2>
              <div className="pt4_document_heading_line">
                <img src={headerLine} alt="img" />
              </div>
            </div>
            <div className="pt4_document_heading_text">
              <p>
                At OneQMS, we understand the critical importance of adhering to
                stringent regulations with Pharma / Pharmaceutical,
                Pharmacovigilance, Clinical trials and maintaining
                uncompromising quality in CROs and pharmaceutical manufacturing.
                Our software is meticulously crafted to empower your
                organization with the following benefits:
              </p>
            </div>
            <div className="pt4_document_section">
              <div className="pt4_document_section_content">
                <div className="pt4_document_section_content_cards">
                  <div className="pt4_document_section_content_cards_icon">
                    <img src={complience} alt="f1" />
                  </div>
                  <div className="pt4_document_section_content_cards_text">
                    <h3>Seamless Compliance</h3>
                  </div>
                  <div className="pt4_document_section_content_cards_data">
                    <p>
                      Stay ahead of evolving regulatory requirements
                      effortlessly with a platform tailored to pharmaceutical
                      industry and CRO standards (e.g., FDA, EMA, ICH).
                    </p>
                  </div>
                </div>
                <div className="pt4_document_section_content_cards">
                  <div className="pt4_document_section_content_cards_icon">
                    <img src={document} alt="f2" />
                  </div>
                  <div className="pt4_document_section_content_cards_text">
                    <h3>Centralized Documentation</h3>
                  </div>
                  <div className="pt4_document_section_content_cards_data">
                    <p>
                      Say goodbye to scattered documents and embrace a
                      centralized repository for all your quality-related
                      documents, with assurance to easy access and version
                      control.
                    </p>
                  </div>
                </div>
                <div className="pt4_document_section_content_cards">
                  <div className="pt4_document_section_content_cards_icon">
                    <img src={audit} alt="f3" />
                  </div>
                  <div className="pt4_document_section_content_cards_text">
                    <h3>Effortless Audits and Inspections</h3>
                  </div>
                  <div className="pt4_document_section_content_cards_data">
                    <p>
                      Our software, simplifies Quality Management in
                      Pharma/Pharmaceutical, Pharmacovigilance, clinical trials,
                      audit preparations and execution through comprehensive
                      tools that organize data and to facilitate assurance in
                      smooth audits, saving time and resources.
                    </p>
                  </div>
                </div>
                <div className="pt4_document_section_content_cards">
                  <div className="pt4_document_section_content_cards_icon">
                    <img src={collab} alt="f4" />
                  </div>
                  <div className="pt4_document_section_content_cards_text">
                    <h3>Enhanced Collaboration</h3>
                  </div>
                  <div className="pt4_document_section_content_cards_data">
                    <p>
                      Foster collaboration across teams, departments, and sites
                      by providing a unified quality management software
                      services for communication and documentation sharing.
                    </p>
                  </div>
                </div>
                <div className="pt4_document_section_content_cards">
                  <div className="pt4_document_section_content_cards_icon">
                    <img src={report} alt="f5" />
                  </div>
                  <div className="pt4_document_section_content_cards_text">
                    <h3>Real-time Monitoring and Reporting</h3>
                  </div>
                  <div className="pt4_document_section_content_cards_data">
                    <p>
                      Access real-time data analytics and insightful reports to
                      make informed decisions swiftly, improving operational
                      efficiency of your pharmacovigilance/pharmaceutical
                      company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt4_cloud">
          <div className="pt4_cloud_container">
            <div className="pt4_cloud_section">
              <div className="pt4_cloud_section_left">
                <div className="pt4_cloud_section_left_data">
                  <h3>Why GO Cloud-Based?</h3>
                  <p>
                    With Astakenis OneQMS Software, access your customizable Quality
                    Management System (QMS) securely anytime, anywhere to meet
                    the unique needs of your pharma/pharmaceutical, clinical,
                    pharmacovigilance or CRO company, empowering you to excel in
                    and beyond. Our cloud-based solution ensures.
                  </p>
                  <img src={CloudImg} alt="CloudImg" />
                </div>
              </div>
              <div className="pt4_cloud_section_right">
                <div className="pt4_cloud_section_right_cards1">
                  <div className="pt4_cloud_section_right_cards1_cardtop">
                    <div className="pt4_cloud_section_right_cards1_cardtop_icon">
                      <img src={CloudIcon1} alt="CloudIcon1" />
                    </div>
                    <h4>Accessibility</h4>
                    <p>Access the system securely from any</p>
                    <p>device with an internet connection.</p>
                  </div>

                  <div className="pt4_cloud_section_right_cards1_cardbottom">
                    <div className="pt4_cloud_section_right_cards1_cardbottom_icon">
                      <img src={CloudIcon2} alt="CloudIcon2" />
                    </div>
                    <h4>Security</h4>
                    <p>Benefit from robust security</p>
                    <p>measures, including data encryption</p>
                    <p>and regular backups, with the </p>
                    <p>assurance of information safety and protection.</p>
                  </div>
                </div>
                <div className="pt4_cloud_section_right_cards2">
                  <div className="pt4_cloud_section_right_cards2_card">
                    <div className="pt4_cloud_section_right_cards2_card_icon">
                      <img src={CloudIcon3} alt="CloudIcon3" />
                    </div>
                    <h4>Scalability</h4>
                    <p>Grow effortlessly with a Quality</p>
                    <p>Management System (QMS)</p>
                    <p>that adapts to your organization's</p>
                    <p>changing needs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt4_bottom">
          <div className="pt4_bottom_image">
            <div className="pt4_bottom_image_text">
              <h3>Experience the Difference with OneQMS</h3>
              <p>
                Join leading Clinical Research Organisation (CRO) and
                pharmaceutical companies worldwide who have embraced our OneQMS
                software to elevate their pharmaceutical quality,
                pharmacovigilance and clinical Quality Management System (QMS).
                Our user-friendly interface, coupled with unparalleled customer
                support services, assurance to seamlessly transit and ongoing success in
                managing your quality processes.
              </p>
            </div>
          </div>
        </div>
        <div className="pt4_request">
          <div className="pt4_request_container">
            <div className="pt4_request_section">
              <div className="pt4_request_section_left">
                <h3>
                  Discover how OneQMS can revolutionize your Quality Management
                  System.
                </h3>
                <p>
                  Experience the difference of working with a company that is
                  committed to driving innovation and excellence in
                  pharma/pharmaceutical quality management, clinical,
                  pharmacovigilance, and regulatory compliance.
                </p>
                <button onClick={handleRequest}>Request a Demo</button>
              </div>
              <div className="pt4_request_section_right">
                <img src={demoImage} alt="demoImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products4;
