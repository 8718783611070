import React, { useEffect } from "react";
import "./ProductTwo.scss";
import ptbanner from "../../assets/images/ProductTwo-banner.png";
import headerLine from "../../assets/images/Line 11.png";
import ptDms from "../../assets/images/pt-dms-img.png";
import secureIcon from "../../assets/images/pt-secure-icon.png";
import mngIcon from "../../assets/images/pt-mng-icon.png";
import auditIcon from "../../assets/images/pt-audit-icon.png";
import workflowIcon from "../../assets/images/pt-workflow-icon.png";
import searchIcon from "../../assets/images/pt-search-icon.png";
import ptwhy1 from "../../assets/images/industry_expertise.png";
import ptwhy2 from "../../assets/images/reliability_and_scalability.png";
import ptwhy3 from "../../assets/images/data_security.png";
import ptwhy4 from "../../assets/images/customer_support.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ProductTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleStart = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <Helmet>
        <title>
          Advanced Document Management software for Pharma, Clinical Trials,
          Pharmacovigilance, and Clinical Research - Astakenis{" "}
        </title>
        <meta
          name="description"
          content="Astakenis is a robust Document Management System designed specifically to meet the unique needs of pharmaceutical companies. It offers a centralized platform for efficiently managing documents, streamlining processes, ensuring compliance, and fostering collaboration across departments"
        />
      </Helmet>
      <div className="pt">
        <div
          className="pt_banner"
          style={{
            backgroundImage: `url(${ptbanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="pt_banner_text">
            <h2>OneQMS - Document Management</h2>
          </div>
        </div>
        <div className="pt_dms">
          <div className="pt_dms_container">
            <div className="pt_dms_section">
              <div className="pt_dms_section_header">
                <h3>Document Management System</h3>
                <div className="pt_dms_section_line">
                  <img src={headerLine} alt="line" />
                </div>
              </div>
              <div className="pt_dms_section_content">
                <div className="pt_dms_section_content_left">
                  <img src={ptDms} alt="ptDms" />
                </div>
                <div className="pt_dms_section_content_right">
                  <div className="pt_dms_section_content_right_heading">
                    <h3>Your Comprehensive Document Management Solution</h3>
                  </div>
                  <div className="pt_dms_section_content_right_data">
                    <p>
                      In the dynamic and highly regulated landscape of
                      pharmaceuticals and Clinical Research Organisation (CRO),
                      streamlined document management is pivotal. Pharma OneQMS
                      Document management software system offers a cutting-edge,
                      secure, and compliant cloud-based Document Management
                      System designed specifically for pharmaceutical and
                      Clinical Research Organisation (CRO) companies.{" "}
                    </p>
                  </div>
                  <div className="pt_dms_section_content_right_heading">
                    <h3>About OneQMS Document</h3>
                  </div>
                  <div className="pt_dms_section_content_right_data">
                    <p>
                      At OneQMS Document Management System, we understand the
                      complexities and stringent requirements surrounding
                      documentation in the pharmaceutical and Clinical Research
                      Organisation (CRO) industry. Our robust Document
                      management software platform is meticulously crafted to
                      empower organizations in managing, accessing, and securing
                      critical documents with ease, efficiency, and utmost
                      compliance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt_features">
          <div className="pt_features_container">
            <div className="pt_features_section">
              <div className="pt_features_section_header">
                <h2>Features and Benefits</h2>
                <div className="pt_features_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="pt_features_section_content">
                <div className="pt_features_section_content_cards">
                  <div className="pt_features_section_content_cards_icon">
                    <img src={secureIcon} alt="img" />
                  </div>
                  <div className="pt_features_section_content_cards_text">
                    <h3>Secure Cloud Storage</h3>
                  </div>
                  <div className="pt_features_section_content_cards_content">
                    <p>
                      Safeguard your sensitive data and intellectual property
                      with our top-tier, encrypted cloud storage system. Access
                      documents anytime, anywhere while ensuring stringent
                      security measures.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="pt_features_section_content_cards">
                  <div className="pt_features_section_content_cards_icon">
                    <img src={mngIcon} alt="img" />
                  </div>
                  <div className="pt_features_section_content_cards_text">
                    <h3>Compliance Management</h3>
                  </div>
                  <div className="pt_features_section_content_cards_content">
                    <p>
                      Stay ahead of regulatory requirements effortlessly in the
                      pharma industry. OneQMS Document Management software is
                      equipped with compliance-centric features ensuring
                      adherence to industry standards such as FDA, EMA, and GxP
                      guidelines for Pharma/Pharmaceutical, clinical, clinical
                      trials and Pharmacovigilance.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="pt_features_section_content_cards">
                  <div className="pt_features_section_content_cards_icon">
                    <img src={auditIcon} alt="img" />
                  </div>
                  <div className="pt_features_section_content_cards_text">
                    <h3>Version Control and Audit Trail</h3>
                  </div>
                  <div className="pt_features_section_content_cards_content">
                    <p>
                      Maintain a comprehensive history of document revisions and
                      changes. Track every alteration made to documents and
                      access a clear audit trail for compliance and
                      accountability.
                    </p>
                  </div>
                </div>

                <div className="pt_features_section_content_cards">
                  <div className="pt_features_section_content_cards_icon">
                    <img src={workflowIcon} alt="img" />
                  </div>
                  <div className="pt_features_section_content_cards_text">
                    <h3>Customizable Workflows</h3>
                  </div>
                  <div className="pt_features_section_content_cards_content">
                    <p>
                      Tailor workflows to align with your organization's
                      specific pharma document handling processes. Automate
                      approval flows and notifications for smoother document
                      lifecycles using our system.
                    </p>
                  </div>
                </div>
                <div className="line"></div>

                <div className="pt_features_section_content_cards">
                  <div className="pt_features_section_content_cards_icon">
                    <img src={searchIcon} alt="img" />
                  </div>
                  <div className="pt_features_section_content_cards_text">
                    <h3>Search and Retrieval</h3>
                  </div>
                  <div className="pt_features_section_content_cards_content">
                    <p>
                      Effortlessly find and retrieve documents using advanced
                      search functionalities tailored to your company's needs.
                      Save time and increase productivity with quick access to
                      relevant information through our service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt_why">
          <div className="pt_why_container">
            <div className="pt_why_section">
              <div className="pt_why_section_header">
                <h2>Why Choose OneQMS Document</h2>
                <div className="pt_why_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>

              <div className="pt_why_section_content">
                <div className="pt_why_section_content_left_class">
                  <div className="pt_why_section_content_left_class_icon">
                    <img src={ptwhy1} alt="img" />
                  </div>
                  <div className="pt_why_section_content_left_class_text">
                    <div className="pt_why_section_content_left_class_text_head">
                      <h3>Industry Expertise</h3>
                    </div>
                    <div className="pt_why_section_content_left_class_text_content">
                      <p>
                        Backed by industry experts, our Document management
                        software is designed with a deep understanding of the
                        unique needs and challenges faced by
                        pharmaceutical/pharma and Clinical Research Organisation
                        (CRO) companies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt_why_section_content_left_class">
                  <div className="pt_why_section_content_left_class_icon">
                    <img src={ptwhy2} alt="img" />
                  </div>
                  <div className="pt_why_section_content_left_class_text">
                    <div className="pt_why_section_content_left_class_text_head">
                      <h3>Reliability and Scalability</h3>
                    </div>
                    <div className="pt_why_section_content_left_class_text_content">
                      <p>
                        Experience a reliable, scalable, and always-accessible
                        document management software that grows with your
                        business requirements, ensuring seamless operations for
                        Pharma, Clinical, pharmacovigilance and clinical trials.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt_why_section_content_left_class">
                  <div className="pt_why_section_content_left_class_icon">
                    <img src={ptwhy3} alt="img" />
                  </div>
                  <div className="pt_why_section_content_left_class_text">
                    <div className="pt_why_section_content_left_class_text_head">
                      <h3>Data Security and Compliance</h3>
                    </div>
                    <div className="pt_why_section_content_left_class_text_content">
                      <p>
                        Rest assured, your data is protected with the highest
                        security protocols and compliance standards, meeting the
                        most stringent regulatory requirements for pharma
                        companies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt_why_section_content_left_class">
                  <div className="pt_why_section_content_left_class_icon">
                    <img src={ptwhy4} alt="img" />
                  </div>
                  <div className="pt_why_section_content_left_class_text">
                    <div className="pt_why_section_content_left_class_text_head">
                      <h3>Customer Support</h3>
                    </div>
                    <div className="pt_why_section_content_left_class_text_content">
                      <p>
                        Benefit from dedicated customer support service
                        committed to assisting your company at every step,
                        ensuring a smooth and successful implementation of our
                        document management software.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt_start">
          <div className="pt_start_container">
            <div className="pt_start_section">
              <div className="pt_start_section_heading">
                <h3>Get Started Today</h3>
              </div>
              <div className="pt_start_section_content">
                <p>
                  Revolutionize your document management practices in the
                  pharmaceutical and Clinical Research Organisation (CRO)
                  industry with OneQMS Document. Contact us now for a
                  personalized demo or to discuss how our solution can cater to
                  your organization's specific needs.
                </p>
                <p>
                  <i>
                    Join the many leading pharmaceutical and Clinical Research
                    Organisation (CRO) companies already benefiting from
                    Astakenis OneQMS Document's cutting-edge Document Management
                    System!
                  </i>
                </p>
              </div>
              <div className="pt_start_section_button">
                <button onClick={handleStart}>Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTwo;
