import React, { useEffect } from "react";
import "./EventDetails.scss";
import { useLocation } from "react-router-dom";
import banner from "../../assets/images/EventsBanner.png";
import { Helmet } from "react-helmet";

const EventDetails = () => {
  const { state } = useLocation();
  const { eventData } = state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sanitizedHTML = eventData?.additionalInformation?.replace(
    /<table/g,
    '<table style="border: 1px solid black; border-collapse: collapse;"'
  ).replace(
    /<td/g,
    '<td style="border: 1px solid black; padding: 5px 10px;"'
  ).replace(
    /<th/g,
    '<th style="border: 1px solid black; padding: 5px 10px;"'
  );

  return (
    <div>
      <Helmet>
        <title>Event Information - Astakenis</title>
        <meta
          name="description"
          content=" Astakenis offers Pharmaceutical Quality Management (QMS) Software Company in Bangalore, India, USA, UK and Netherlands"
        />
      </Helmet>
      <div className="ed">
        <div
          className="ed_banner"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="ed_banner_heading">
            <h1>Event Information</h1>
          </div>
        </div>
        <div className="ed_cont">
          <div className="ed_cont_sec">
            <div
            className="custom-table"
              dangerouslySetInnerHTML={{
                __html: eventData?.additionalInformation,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
