export const SearchIcon = ({ className }) => {
    return (
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7871 13.6626L10.3359 9.94962C11.2233 8.85844 11.7095 7.48552 11.7095 6.05624C11.7095 2.71688 9.08299 0 5.85474 0C2.62649 0 0 2.71688 0 6.05624C0 9.3956 2.62649 12.1125 5.85474 12.1125C7.06667 12.1125 8.22158 11.7344 9.20899 11.0166L12.6864 14.7578C12.8318 14.9139 13.0273 15 13.2368 15C13.4351 15 13.6232 14.9218 13.766 14.7796C14.0694 14.4776 14.0791 13.9768 13.7871 13.6626ZM5.85474 1.57989C8.24092 1.57989 10.1821 3.58793 10.1821 6.05624C10.1821 8.52456 8.24092 10.5326 5.85474 10.5326C3.46855 10.5326 1.52732 8.52456 1.52732 6.05624C1.52732 3.58793 3.46855 1.57989 5.85474 1.57989Z"
          fill="#707070"
        />
      </svg>
    );
  };