import React, { useEffect } from "react";
import "./Products.scss";
import banner from "../../assets/images/oneqms.banner.jpg";
import headerLine from "../../assets/images/Line 11.png";
import system from "../../assets/images/Quality management about.png";
import key1 from "../../assets/images/key1.png";
import key2 from "../../assets/images/key2.png";
import key3 from "../../assets/images/key3.png";
import key4 from "../../assets/images/key4.png";
import key5 from "../../assets/images/key5.png";
import key6 from "../../assets/images/key6.png";
import key7 from "../../assets/images/key7.png";
import security from "../../assets/images/security_complience.png";
import arrow from "../../assets/images/scalability_flexibility.png";
import team from "../../assets/images/user_friendly_interface.png";
import users from "../../assets/images/collaboration_integration.png";
import { Helmet } from "react-helmet";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Comprehensive Quality Management Software Solutions for Pharma,
          Clinical Trials, Pharmacovigilance, and Clinical Research - Astakenis
        </title>
        <meta
          name="description"
          content="Astakenis is a leading pharmaceutical Quality Management System (QMS) software company based in Bangalore, India"
        />
      </Helmet>
      <div className="products">
        <div className="products_container">
          <div
            className="products_container_banner"
            style={{
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="products_container_banner_heading">
              <h1>OneQMS - Quality Management</h1>
            </div>
          </div>
        </div>
        <div className="products_system">
          <div className="products_system_container">
            <div className="products_system_section">
              <div className="products_system_section_header">
                <h2>Quality Management System</h2>
                <div className="products_system_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products_system_section_content">
                <div className="products_system_section_content_left">
                  <div className="products_system_section_content_left_header">
                    {/* <h3>OneQMS </h3> */}
                  </div>
                  <div className="products_system_section_content_left_data">
                    <p>
                      In an industry where precision, compliance, and safety are
                      paramount, streamline your quality management processes
                      with Astakenis OneQMS Quality Management software (QMS) - your
                      comprehensive cloud-based solution designed specifically
                      for pharmaceutical companies and Clinical Research Organisation (CROs).
                    </p>
                  </div>
                  <div className="products_system_section_content_left_header">
                    <h3>About OneQMS Quality </h3>
                  </div>
                  <div className="products_system_section_content_left_data">
                    <p>
                      Astakenis OneQMS Quality Management Software (QMS) is a
                      cutting-edge Quality Management System meticulously
                      crafted to meet the unique demands of the pharmaceutical
                      and CRO sectors. Our cloud-based platform offers an
                      integrated suite of tools and features to simplify,
                      automate, and enhance your quality assurance processes,
                      ensuring adherence to regulatory standards while
                      optimizing efficiency of your company.
                    </p>
                  </div>
                </div>
                <div className="products_system_section_content_right">
                  <div className="products_system_section_content_right_image">
                    <img src={system} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products_features">
          <div className="products_features_container">
            <div className="products_features_section">
              <div className="products_features_section_header">
                <h2>Key Features</h2>
                <div className="products_features_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products_features_section_content">
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key1} alt="key1" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>CAPA Management</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Effectively manage Corrective and Preventive Actions
                      (CAPA) through a streamlined workflow, identifying root
                      causes, initiating corrective actions, and tracking
                      progress to prevent recurrence in Pharma/Pharmaceutical,
                      Clinical, Pharmacovigilance, and Clinical Trials.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key2} alt="key2" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Change Control</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Implement controlled changes with ease. Our system enables
                      you to track, review, approve, and manage changes
                      efficiently while maintaining an audit trail for
                      compliance purposes.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key3} alt="key3" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Audit Management</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Prepare for audits seamlessly with our audit module,
                      scheduling, and tracking capabilities. Maintain compliance
                      and demonstrate your commitment to quality with
                      confidence.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key4} alt="key4" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Customer Complaint</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Manage your market complaints effectively in pharma to
                      ensures that the product is legally sound, safe, and
                      aligned with industry best practices, while providing
                      exceptional software service.
                    </p>
                  </div>
                </div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key5} alt="key5" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Quality Issues</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Quality issue module shall deal specifically with
                      identifying, evaluating, tracking and resolving
                      pharmaceutical and Clinical Research Organisation (CRO) quality-related problems or
                      defects.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key6} alt="key6" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Deviation Management</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Handling and addressing deviations or variations from
                      predefined standards, procedures, or expected outcomes
                      within a system, process, or project. Deviation module is
                      integrated with CAPA to more effectively manage your
                      non-conformances.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products_features_section_content_cards">
                  <div className="products_features_section_content_cards_icon">
                    <img src={key7} alt="key7" />
                  </div>
                  <div className="products_features_section_content_cards_text">
                    <h3>Incident Management</h3>
                  </div>
                  <div className="products_features_section_content_cards_content">
                    <p>
                      Effectively identify, evaluate, respond, and resolve
                      incidents that occur within an organization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products_quality">
          <div className="products_quality_container">
            <div className="products_quality_section">
              <div className="products_quality_section_header">
                <h2>Why Choose OneQMS Quality</h2>
                <div className="products_quality_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products_quality_section_content">
                <div className="products_quality_section_content_left">
                  <div className="products_quality_section_content_left_class">
                    <div className="products_quality_section_content_left_class_icon">
                      <img src={security} alt="img" />
                    </div>
                    <div className="products_quality_section_content_left_class_text">
                      <div className="products_quality_section_content_left_class_text_head">
                        <h3>Security & Compliance</h3>
                      </div>
                      <div className="products_quality_section_content_left_class_text_content">
                        <p>
                          Our platform prioritizes data security and regulatory
                          compliance, employing robust measures to safeguard
                          your sensitive company information and ensure
                          adherence to industry standards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="products_quality_section_content_left_class">
                    <div className="products_quality_section_content_left_class_icon">
                      <img src={users} alt="users" />
                    </div>
                    <div className="products_quality_section_content_left_class_text">
                      <div className="products_quality_section_content_left_class_text_head">
                        <h3>Collaboration & Integration</h3>
                      </div>
                      <div className="products_quality_section_content_left_class_text_content">
                        <p>
                          Foster collaboration among Pharma/Pharmaceutical,
                          Clinical, Pharmacovigilance teams with real-time
                          access to information. Integration capabilities allow
                          seamless connectivity with other enterprise systems
                          for your company.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="products_quality_section_content_right">
                  <div className="products_quality_section_content_right_class">
                    <div className="products_quality_section_content_right_class_icon">
                      <img src={team} alt="team" />
                    </div>
                    <div className="products_quality_section_content_right_class_text">
                      <div className="products_quality_section_content_right_class_text_head">
                        <h3>User-Friendly Interface</h3>
                      </div>
                      <div className="products_quality_section_content_right_class_text_content">
                        <p>
                          Designed for ease of use, our intuitive interface
                          requires minimal training, enabling swift adoption
                          across your Pharma, Pharmaceutical, Clinical and
                          pharmacovigilance company workforce.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="products_quality_section_content_right_class">
                    <div className="products_quality_section_content_right_class_icon">
                      <img src={arrow} alt="arrow" />
                    </div>
                    <div className="products_quality_section_content_right_class_text">
                      <div className="products_quality_section_content_right_class_text_head">
                        <h3>Scalability & Flexibility</h3>
                      </div>
                      <div className="products_quality_section_content_right_class_text_content">
                        <p>
                          Scale your operations effortlessly and adapt to
                          evolving industry requirements with a flexible and
                          customizable QMS software that grows with your
                          organization.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products_experience">
          <div className="products_experience_container">
            <div className="products_experience_container_banner">
              <div className="products_experience_container_banner_sections">
                <div className="products_experience_container_banner_sections_left">
                  <div className="products_experience_container_banner_sections_left_section">
                    <h3>Experience the Future of Quality Management</h3>
                    <div className="line"></div>
                    <p>
                      Empower your pharmaceutical / Clinical Research
                      Organisation (CRO) business with Astakenis OneQMS-Quality
                      Management software solution. Join numerous industry
                      leaders who rely on our innovative solution to drive
                      efficiency, compliance, and excellence in quality
                      management.
                    </p>
                  </div>
                </div>
                <div className="products_experience_container_banner_sections_right">
                  <div className="products_experience_container_banner_sections_right_section">
                    <h3>Get Started Today</h3>
                    <div className="line"></div>
                    <p>
                      Contact us for a personalized demo or to discuss how our
                      OneQMS - Quality Management (QMS) software can
                      revolutionize your quality management processes, ensuring
                      compliance, reliability, and success in a competitive
                      pharmaceutical landscape, including pharmacovigilance,
                      clinical trials and pharma/pharmaceutical services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
