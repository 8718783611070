import React, { useEffect } from "react";
import "./Products3.scss";
import banner from "../../assets/images/trainingmanegementbnr.jpg";
import headerLine from "../../assets/images/Line 11.png";
import training from "../../assets/images/Training management about.png";
import frame1 from "../../assets/images/p3-key1.png";
import frame2 from "../../assets/images/p3-key2.png";
import frame3 from "../../assets/images/p3-key3.png";
import frame4 from "../../assets/images/p3-key4.png";
import frame5 from "../../assets/images/p3-key5.png";
import frame6 from "../../assets/images/p3-key6.png";
import frame7 from "../../assets/images/p3-key7.png";
import industry from "../../assets/images/industry_expertise.png";
import data from "../../assets/images/data_security.png";
import support from "../../assets/images/dedicated_support.png";
import bottom from "../../assets/images/TMS- learn more.png";
import Blue from "../../assets/images/BlueHalfCircle.png";
import Orange from "../../assets/images/OrangeHalfCircle.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Products3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleTouch = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <Helmet>
        <title>
          Specialized Training Management Solutions for Pharma, Clinical Trials,
          Pharmacovigilance, and Clinical Research - Astakenis{" "}
        </title>
        <meta
          name="description"
          content="Astakenis is a comprehensive Training Management System (TMS) designed specifically for the pharmaceutical industry. Streamline training processes, track compliance, and enhance workforce skills efficiently with our intuitive platform"
        />
      </Helmet>
      <div className="products3">
        <div className="products3_container">
          <div
            className="products3_container_banner"
            style={{
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div className="products3_container_banner_heading">
              <h1>OneQMS - Training Management</h1>
            </div>
          </div>
        </div>

        <div className="products3_training">
          <div className="products3_training_container">
            <div className="products3_training_section">
              <div className="products3_training_section_header">
                <h2>Training Management System</h2>
                <div className="products3_training_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products3_training_section_content">
                <div className="products3_training_section_content_left">
                  <div className="products3_training_section_content_left_header">
                    <h3>
                      Cutting-Edge Cloud-Based Training Management Solution{" "}
                    </h3>
                  </div>
                  <div className="products3_training_section_content_left_data">
                    <p>
                      Are you seeking a streamlined, efficient, and compliant
                      training system for your pharmaceutical and Clinical
                      Research Organisation (CRO) company? Look no further than
                      OneQMS Training- the innovative Cloud-Based Training
                      Management System tailored specifically for the unique
                      needs of the pharmaceutical and Clinical Research
                      Organisation (CRO) industry.
                    </p>
                  </div>
                  <br />
                  <div className="products3_training_section_content_left_data">
                    <p>
                      At OneQMS Training, we understand the critical importance
                      of adhering to stringent regulations while ensuring that
                      your workforce remains highly skilled and up-to-date with
                      the latest industry standards. Our robust cloud-based
                      platform is designed to simplify, automate, and optimize
                      your training processes, enabling you to focus on
                      delivering high-quality pharmaceutical products and
                      services.
                    </p>
                  </div>
                </div>
                <div className="products3_training_section_content_right">
                  <div className="products3_training_section_content_right_image">
                    <img src={training} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products3_features">
          <div className="products3_features_container">
            <div className="products3_features_section">
              <div className="products3_features_section_header">
                <h2>Key Features</h2>
                <div className="products3_features_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products3_features_section_content">
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame1} alt="f1" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Comprehensive Compliance</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Stay compliant with industry regulations effortlessly with
                      our service. OneQMS Training is equipped with features
                      that help you manage training records, certifications, and
                      compliance requirements seamlessly for your company.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame2} alt="f2" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Customized Learning Groups</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Tailor training programs to suit various roles within your
                      organization. Create personalized learning groups for
                      different departments, ensuring that employees receive
                      targeted training relevant to their responsibilities in
                      Pharma/Pharmaceutical, Pharmacovigilance, Clinical and
                      Clinical Research Organisation (CRO) operations.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame3} alt="f3" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Centralized Training Repository</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Access all training materials, modules, and resources from
                      a centralized repository. This facilitates easy retrieval,
                      sharing, and updating of learning content across your
                      organization.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame4} alt="f4" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>User-Friendly Interface</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Our intuitive interface makes it simple for both
                      administrators and learners to navigate the system. Easily
                      assign courses, track progress, and generate reports with
                      just a few clicks using our service.
                    </p>
                  </div>
                </div>

                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame5} alt="f5" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Real-Time Progress Tracking</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Monitor employee progress in real-time within your pharma
                      company. Track completion rates, assessment scores, and
                      identify areas that may require additional focus or
                      resources.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame6} alt="f6" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Scalability and Accessibility</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      As a cloud-based solution, OneQMS Training offers
                      scalability and accessibility. Access training materials
                      anytime, anywhere, fostering continuous learning and
                      development.
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="products3_features_section_content_cards">
                  <div className="products3_features_section_content_cards_icon">
                    <img src={frame7} alt="f7" />
                  </div>
                  <div className="products3_features_section_content_cards_text">
                    <h3>Integration Capabilities</h3>
                  </div>
                  <div className="products3_features_section_content_cards_content">
                    <p>
                      Seamlessly integrate OneQMS Training with OneQMS Documents
                      to manage your company's procedural document trainings for
                      Pharma, Pharmaceutical, Clinical and Pharmacovigilance
                      companies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="products3_document">
          <div className="orange">
            <img src={Orange} alt="" />
          </div>
          <div className="blue">
            <img src={Blue} alt="" />
          </div>
          <div className="products3_document_container">
            <div className="products3_document_section">
              <div className="products3_document_section_header">
                <h2>Why Choose OneQMS Training</h2>
                <div className="products3_document_section_header_image">
                  <img src={headerLine} alt="img" />
                </div>
              </div>
              <div className="products3_document_section_content">
                <div className="products3_document_section_content_class">
                  <div className="products3_document_section_content_class_icon">
                    <img src={industry} alt="industry" />
                  </div>
                  <div className="products3_document_section_content_class_text">
                    <div className="products3_document_section_content_class_text_head">
                      <h3>Industry Expertise</h3>
                    </div>
                    <div className="products3_document_section_content_class_text_content">
                      <p>
                        Our team comprises professionals with in-depth knowledge
                        of both the pharmaceutical sector and cutting-edge
                        technology, ensuring a robust TMS.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products3_document_section_content_class">
                  <div className="products3_document_section_content_class_icon">
                    <img src={data} alt="data" />
                  </div>
                  <div className="products3_document_section_content_class_text">
                    <div className="products3_document_section_content_class_text_head">
                      <h3>Data Security</h3>
                    </div>
                    <div className="products3_document_section_content_class_text_content">
                      <p>
                        Rest assured, your sensitive training data is secure
                        with us. We prioritize data encryption, regular backups,
                        and stringent security measures to protect your
                        information.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products3_document_section_content_class">
                  <div className="products3_document_section_content_class_icon">
                    <img src={support} alt="support" />
                  </div>
                  <div className="products3_document_section_content_class_text">
                    <div className="products3_document_section_content_class_text_head">
                      <h3>Dedicated Support</h3>
                    </div>
                    <div className="products3_document_section_content_class_text_content">
                      <p>
                        Receive dedicated support from our team of experts who
                        are committed to assisting you at every stage, from
                        implementation to ongoing support and updates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="products3_bottom">
          <div className="products3_bottom_container">
            <div className="products3_bottom_container_left">
              <div className="products3_bottom_container_left_text">
                <p>
                  Transform your pharmaceutical and Clinical Research
                  Organisation (CRO) company's training landscape with OneQMS
                  Training. Elevate your workforce's skills, maintain compliance
                  effortlessly, and drive your organization's success with our
                  state-of-the-art Cloud-Based Training Management System.
                </p>
                <p>
                  Learn more about how{" "}
                  <span style={{ color: "#F88E25" }}>OneQMS</span> Training can
                  revolutionize your training processes!
                </p>
                <button onClick={handleTouch}>Get In Touch</button>
              </div>
            </div>
            <div className="products3_bottom_container_right">
              <div className="products3_bottom_container_right_image">
                <img src={bottom} alt="bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products3;
