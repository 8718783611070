/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Header from "../components/Header/Header";
import AboutUs from "../pages/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";
import ProductTwo from "../pages/ProductTwo/ProductTwo";
import ContactUs from "../pages/ContactUs/ContactUs";
import Products from "../pages/Products/Products";
import Products3 from "../pages/products3/Products3";
import Products4 from "../pages/Products4/Products4";
import Events from "../pages/Events/Events";
import EventDetails from "../pages/EventDetails/EventDetails";

const RouterComopnent = () => {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/quality-management-system" element={<Products />} />
        <Route path="/document-management-system" element={<ProductTwo />} />
        <Route path="/training-management-system" element={<Products3 />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event-details" element={<EventDetails />} />
        <Route path="/one-qms" element={<Products4 />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default RouterComopnent;
