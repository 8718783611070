import React from "react";
import { useState } from "react";
import "./Home.scss";
import banner1 from "../../assets/images/banner.jpg";
import headerLine from "../../assets/images/Line 11.png";
import efficiency from "../../assets/images/Efficiency.png";
import platform from "../../assets/images/Platform.png";
import innovative from "../../assets/images/Innovation.png";
import future from "../../assets/images/Future.png";
import solutions from "../../assets/images/Solutions.png";
import homeclient1 from "../../assets/images/homeclient1.png";
import homeclient2 from "../../assets/images/homeclient2.png";
import homeclient3 from "../../assets/images/homeclient3.png";
import homeclient4 from "../../assets/images/homeclient4.png";
import homeclient5 from "../../assets/images/ClientV.png";
import homeclient6 from "../../assets/images/ClientClinevo.png";
import homeclient7 from "../../assets/images/ClientPharmayas.png";
import homeclient8 from "../../assets/images/ClientA&Z.png";
import leftArrow from "../../assets/images/arrow-left.png";
import rightArrow from "../../assets/images/arrow-right.png";
import abtImg from "../../assets/images/home-abt-img.png";
import { useNavigate } from "react-router-dom";
import qms from "../../assets/images/qmsproduct.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import OneQMS from "../../assets/images/MobileOneQMS.png";
import QMS from "../../assets/images/MobileQMS.png";
import DMS from "../../assets/images/MobileDMS.png";
import TMS from "../../assets/images/MobileTMS.png";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();

  const handleProductPage = () => {
    navigate("/one-qms");
  };

  const handleAboutUsPage = () => {
    navigate("/about");
  };

  const handleQmsProduct1 = () => {
    navigate("/one-qms");
  };

  const handleQmsProduct2 = () => {
    navigate("/quality-management-system");
  };

  const handleQmsProduct3 = () => {
    navigate("/document-management-system");
  };

  const handleQmsProduct4 = () => {
    navigate("/training-management-system");
  };

  const contentData = [
    {
      heading: "Efficiency",
      text: "OneQMS aims to streamline and improve the efficiency of quality management processes, ensuring compliance assurance along with industry regulations and standards. Experience unparalleled efficiency and accuracy in pharmaceutical, pharmacovigilance, and clinical trials Quality management through our advanced OneQMS.",
      image: efficiency,
    },
    {
      heading: "Centralized Platform",
      text: "Astakenis OneQMS appears to be a cloud-based Quality Management System (QMS) designed to assist organizations in efficiently managing their quality and compliance processes digitally. It offers a centralized platform for various quality assurance related activities, including document management, training, Change Control, Corrective and Preventive Actions (CAPA), deviation, incidents, quality issues, customer complaints, and audits.",
      image: platform,
    },
    {
      heading: "Innovation",
      text: "Our company gives you access to cutting-edge technology designed specifically for the Life Sciences industry. We take pride in our deep understanding of the unique requirements within the Life Sciences domain. Our team consists of highly skilled professionals with extensive experience in both technology and Life Sciences, to give you an assurance that we deliver solutions that align seamlessly with the industry's specific needs and regulations.",
      image: innovative,
    },
    {
      heading: "Tailored Solutions",
      text: "Customized approaches addressing the specific needs and challenges of each client. We excel in pioneering technology solutions tailored exclusively for the Life Sciences industry. With a relentless commitment to innovation, we specialize in developing and implementing robust, cutting-edge technologies that drive efficiency and excellence in Pharma / Pharmaceutical, Pharmacovigilance, Biotech, and Clinical Research Organisation (CROs).",
      image: solutions,
    },
    {
      heading: "Future-Proofing",
      text: "Leveraging the latest advancements for the assurance of long-term success and relevance in a rapidly evolving industry. Our team comprises seasoned experts and visionaries passionate about leveraging technology to transform the landscape of Life Sciences R&D. We collaborate closely with our clients, leveraging our in-depth domain knowledge and technical prowess to address their unique challenges and deliver tailor-made solutions.",
      image: future,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleArrowClick = (direction) => {
    const newIndex =
      (currentIndex + direction + contentData.length) % contentData.length;
    setCurrentIndex(newIndex);
  };

  const clients = [
    {
      image: homeclient1,
    },
    {
      image: homeclient2,
    },
    {
      image: homeclient3,
    },
    {
      image: homeclient4,
    },
    {
      image: homeclient5,
    },

    {
      image: homeclient6,
    },
    {
      image: homeclient7,
    },
    {
      image: homeclient8,
    },
  ];

  return (
    <div className="home">
      <Helmet>
        <title>
          Comprehensive Quality Management Software Company for Pharma, Clinical
          Trials, Pharmacovigilance, and Clinical Research - Astakenis
        </title>
        <meta
          name="description"
          content="Astakenis, a pioneering provider of cutting-edge, cloud-based Quality Management System (QMS) Software solutions specifically designed for the demanding requirements of the pharmaceutical and Contract Research Organization (CRO) sector"
        />
      </Helmet>
      <div className="home_container">
        <div
          className="home_container_banner"
          style={{
            backgroundImage: `url(${banner1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="home_container_banner_banner-section">
            <div className="home_container_banner_banner-section_banner-text">
              <h1>
                Revolutionizing <span>Quality Management</span> for Pharma and
                CRO
              </h1>
              <p>
                Introducing OneQMS, your all-in-one, cloud-based quality
                management software solution designed specifically to streamline
                and elevate your Quality Management System (QMS) processes in
                the pharmaceutical industry.
              </p>
              <button onClick={handleProductPage}>Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <div className="home_about">
        <div className="home_about_container">
          <div className="home_about_section">
            <div className="home_about_section_header">
              <h2>About Us</h2>
              <img src={headerLine} alt="img" />
            </div>
            <div className="home_about_section_content">
              <div className="home_about_section_content_left">
                <div className="home_about_section_content_left_header">
                  {/* <h3>
                    OneQMS - Revolutionizing Quality Management for Pharma &
                    CROs
                  </h3> */}
                </div>
                <div className="home_about_section_content_left_data">
                  <p>
                    Welcome to <span>Astakenis</span>, a pioneering company
                    provider of cutting-edge, cloud-based Quality Management
                    System (QMS) Software solutions specifically designed for
                    the demanding requirements of the Pharmaceutical/Pharma,
                    Pharmacovigilance and Clinical Research Organization (CRO)
                    sector. Our company understands the critical importance of
                    ensuring stringent quality assurance standards and
                    regulatory compliance within Pharmaceutical / Pharma,
                    Pharmacovigilance, Clinical and Clinical Research
                    Organisation (CRO) industry.
                  </p>
                </div>
                <div className="home_about_section_content_left_button">
                  <button onClick={handleAboutUsPage}>Learn more</button>
                </div>
              </div>
              <div className="home_about_section_content_right">
                <img src={abtImg} alt="abtImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_product">
        <div className="home_product_container">
          <div className="home_product_header">
            <h2>Our Products</h2>
            <div className="home_product_header_line">
              <img src={headerLine} alt="line" />
            </div>
          </div>
          <div className="home_product_section">
            <img src={qms} alt="qms" />
            <button className="bqms" onClick={handleQmsProduct1}></button>
            <button className="bqms1" onClick={handleQmsProduct2}></button>
            <button className="bqms2" onClick={handleQmsProduct3}></button>
            <button className="bqms3" onClick={handleQmsProduct4}></button>
          </div>
          <div className="home_product_content">
            <div className="home_product_content_qms">
              <p>
                OneQMS Quality is a cutting-edge Quality Management System
                offering products and services designed to streamline
                Pharmaceutical/Pharma quality, pharmacovigilance quality,
                clinical and clinical trial quality, and enhance overall service
                delivery to meet the unique demands of the
                Pharmaceutical/Pharma, Pharmacovigilance, Clinical and Clinical
                Research Organisation (CRO) sectors. Our company's cloud-based
                quality management software offers an integrated suite of tools
                and features to simplify, automate and 
                <span onClick={handleQmsProduct2}>...</span>
              </p>
            </div>
            <div className="home_product_content_dms">
              <p>
                At OneQMS Document, we empower your organization with our
                comprehensive software products and services, revolutionizing
                Pharmaceutical / Pharma quality, pharmacovigilance quality, and
                clinical trials quality in the pharmaceutical and Clinical Research Organisation (CRO) industry.
                Our company's robust platform is meticulously crafted to give
                quality assurance and empower organizations in managing,
                accessing, and securing critical documents
                <span onClick={handleQmsProduct3}>...</span>
              </p>
            </div>
            <div className="home_product_content_tms">
              <p>
                OneQMS Training ensures staying ahead of regulatory requirements
                and industry standards with our state-of-the-art Management
                System, meticulously designed to optimize pharmaceutical
                quality, pharmacovigilance quality, and clinical trials quality,
                and quality services Our company's robust cloud-based quality
                management software is designed to simplify, automate, and
                <span onClick={handleQmsProduct4}>...</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home_mp">
        <div className="home_mp_container">
          <div className="home_mp_header">
            <img src={OneQMS} alt="OneQMS" />
            <button className="mpbqms" onClick={handleQmsProduct1}></button>
          </div>
          <div className="home_mp_section">
            <div className="home_mp_section_top">
              <img src={QMS} alt="QMS" />
              <button className="mpbqms1" onClick={handleQmsProduct2}></button>
            </div>
            <div className="home_mp_section_bottom">
              <p>
                OneQMS Quality is a cutting-edge Quality Management System
                meticulously crafted to meet the unique demands of the
                pharmaceutical and CRO sectors. Our cloud-based platform offers
                an integrated suite of tools and features to simplify, automate,
                and enhance...
              </p>
            </div>
          </div>
          <div className="home_mp_section">
            <div className="home_mp_section_top">
              <img src={DMS} alt="DMS" />
              <button className="mpbqms2" onClick={handleQmsProduct3}></button>
            </div>
            <div className="home_mp_section_bottom">
              <p>
                At OneQMS Document , we understand the complexities and
                stringent requirements surrounding documentation in the
                pharmaceutical and CRO industry. Our robust platform is
                meticulously crafted to empower organizations in managing,
                accessing, and securing critical documents...
              </p>
            </div>
          </div>
          <div className="home_mp_section">
            <div className="home_mp_section_top">
              <img src={TMS} alt="TMS" />
              <button className="mpbqms3" onClick={handleQmsProduct4}></button>
            </div>
            <div className="home_mp_section_bottom">
              <p>
                At OneQMS Training, we understand the critical importance of
                adhering to stringent regulations while ensuring that your
                workforce remains highly skilled and up-to-date with the latest
                industry standards. Our robust cloud-based platform is designed
                to simplify, automate, and...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home_qms">
        <div className="home_qms_body">
          <div className="home_qms_body_container">
            <div className="home_qms_body_container_heading">
              <h3>Why Astakenis OneQMS</h3>
              <div className="home_qms_body_container_heading_image">
                <img src={headerLine} alt="line" />
              </div>
            </div>
            <div className="home_qms_body_container_section">
              <div className="home_qms_body_container_section_left">
                <div className="home_qms_body_container_section_left_content">
                  <div className="home_qms_body_container_section_left_content_heading">
                    <h2>{contentData[currentIndex].heading}</h2>
                  </div>
                  <div className="home_qms_body_container_section_left_content_text">
                    <p>{contentData[currentIndex].text}</p>
                  </div>
                  {/* <div className="home_qms_body_container_section_left_button">
                    <h3>Learn more</h3>
                    <div className="home_qms_body_container_section_left_button_border"></div>
                  </div> */}
                </div>
              </div>
              <div className="home_qms_body_container_section_right">
                <div className="home_qms_body_container_section_right_images">
                  {contentData.map((item, index) => (
                    <img
                      key={index}
                      src={
                        contentData[(currentIndex + index) % contentData.length]
                          .image
                      }
                      alt={
                        contentData[(currentIndex + index) % contentData.length]
                          .heading
                      }
                      className={`${index === 0 ? "active" : ""}`}
                      onClick={() =>
                        setCurrentIndex(
                          (currentIndex + index) % contentData.length
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="home_qms_body_container_bottom">
              <div className="home_qms_body_container_bottom_section">
                <div className="home_qms_body_container_bottom_section_arrows">
                  <img
                    src={leftArrow}
                    alt="leftArrow"
                    onClick={() => handleArrowClick(-1)}
                  />
                  <img
                    src={rightArrow}
                    alt="leftArrow"
                    onClick={() => handleArrowClick(1)}
                  />
                </div>
                <div className="home_qms_body_container_bottom_section_line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_client">
        <div className="home_client_body">
          <div className="home_client_body_clients">
            <div className="home_client_body_clients_container">
              <div className="home_client_body_clients_container_heading">
                <h2>Our Clients</h2>
                <div className="home_client_body_clients_container_heading_line">
                  <img src={headerLine} alt="line" />
                </div>
              </div>
              <div className="home_client_body_clients_container_section">
                <div className="sliding-div">
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={4}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                  >
                    {clients.map((client, index) => (
                      <SwiperSlide key={index}>
                        <img src={client.image} alt={`client${index + 1}`} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
