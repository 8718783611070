import React, { useEffect } from "react";
import "./AboutUs.scss";
import banner1 from "../../assets/images/banner-aboutus.jpg";
import lineImage from "../../assets/images/Line 11.png";
import image1 from "../../assets/images/Group 98387.png";
import image2 from "../../assets/images/About us - 2.png";
import image3 from "../../assets/images/Group 98271.png";
import image4 from "../../assets/images/Group 98272.png";
import logo1 from "../../assets/images/group_1.svg";
import logo2 from "../../assets/images/group_5.svg";
import logo3 from "../../assets/images/group_4.svg";
import logo4 from "../../assets/images/group_3.svg";
import logo5 from "../../assets/images/group_2.svg";
import banner2 from "../../assets/images/banner2.png";
import { useNavigate } from "react-router-dom";
import abtus3 from "../../assets/images/abtus3.jpg";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleJoinUs = () => {
    navigate("/contact-us");
  };

  return (
    <div className="about-us">
      <Helmet>
        <title>About US  - Astakenis</title>
        <meta name="description" content="Astakenis, a pioneering provider of cutting-edge, cloud-based Quality Management (QMS) Software solutions specifically designed for the demanding requirements of the pharmaceutical and Contract Research Organization (CRO) sector" />
      </Helmet>
      <div className="about-us_container">
        <div
          className="about-us_container_banner"
          style={{
            backgroundImage: `url(${banner1})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="about-us_container_banner_banner-text">
            <h1>About Us</h1>
          </div>
        </div>
        <div className="about-us_container_aboutUs-section">
          {/* <div className="about-us_container_aboutUs-section_heading">
            <h1>About Us</h1>
            <img src={lineImage} alt="lineImage" />
          </div> */}
          <div className="about-us_container_aboutUs-section_content">
            <div className="about-us_container_aboutUs-section_content_section1">
              <div className="about-us_container_aboutUs-section_content_section1_left">
                <p>
                  <span className="astakeins-text1">Astakenis</span>, a
                  pioneering provider of cutting-edge, cloud-based Quality
                  Management (QMS) Software solutions specifically designed for
                  the demanding requirements of the pharmaceutical and Contract
                  Research Organization (CRO) sector.
                </p>

                <p>
                  At <span className="astakeins-text1">Astakenis</span>, we
                  understand the critical importance of ensuring stringent
                  quality standards and regulatory compliance within the
                  pharmaceutical and CRO industry. Our mission is to empower the
                  companies with innovative technology that streamlines and
                  enhances their quality management processes, enabling them to
                  achieve unparalleled efficiency, compliance, and excellence in
                  their operations.
                </p>
              </div>
              <div className="about-us_container_aboutUs-section_content_section1_right">
                <div className="image1">
                  <img src={image1} alt="image1" />
                </div>
              </div>
            </div>
            <div className="about-us_container_aboutUs-section_content_section2">
              <div className="about-us_container_aboutUs-section_content_section2_left">
                <div className="image2">
                  <img src={image2} alt="image2" />
                </div>
              </div>
              <div className="about-us_container_aboutUs-section_content_section2_right">
                <p>
                  What sets us apart is our unwavering commitment to delivering
                  intuitive, scalable, and fully integrated QMS solutions that
                  address the complex and evolving needs of the healthcare
                  landscape. Leveraging the latest advancements in cloud
                  technology, we offer a comprehensive suite of tools and
                  modules that cover the entire spectrum of quality management,
                  encompassing documentation control, CAPA (Corrective and
                  Preventive Actions), deviation management, change control,
                  audits, risk management, trainings and more.
                </p>

                <p>
                  Our team comprises seasoned industry experts, software
                  engineers, and regulatory compliance specialists dedicated to
                  crafting sophisticated, yet user-friendly software solutions.
                  We collaborate closely with our clients, understanding their
                  unique challenges, and customizing our software to align
                  seamlessly with their specific workflows and compliance
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us_container_QMS-section">
          <div className="about-us_container_QMS-section_content">
            <div className="about-us_container_QMS-section_content_heading">
              <h1>OneQMS Solutions</h1>
              <img src={lineImage} alt="lineImage" />
            </div>
            <div className="about-us_container_QMS-section_content_card-section">
              <div className="about-us_container_QMS-section_content_card-section_card">
                <div className="image1">
                  <img src={image3} alt="image3" />
                </div>
                <div className="card-logo">
                  <img src={logo1} alt="logo1" />
                </div>
                <div className="card-text">
                  <h2>Comprehensive Compliance</h2>
                  <p>
                    Our software ensures adherence to the strictest regulatory
                    standards, including FDA, EMA, GxP, and ISO guidelines,
                    easing the burden of compliance for pharmaceutical
                    companies.
                  </p>
                </div>
                <div className="image2">
                  <img src={image4} alt="image4" />
                </div>
              </div>
              <div className="about-us_container_QMS-section_content_card-section_card">
                <div className="image1">
                  <img src={image3} alt="image1" />
                </div>
                <div className="card-logo">
                  <img src={logo2} alt="logo2" />
                </div>
                <div className="card-text">
                  <h2>Scalable and Flexible</h2>
                  <p>
                    Designed to adapt and scale alongside your organization, our
                    OneQMS solutions cater to the needs of both small-scale
                    pharmaceutical firms and multinational enterprises.
                  </p>
                </div>
                <div className="image2">
                  <img src={image4} alt="image4" />
                </div>
              </div>
              <div className="about-us_container_QMS-section_content_card-section_card">
                <div className="image1">
                  <img src={image3} alt="image3" />
                </div>
                <div className="card-logo">
                  <img src={logo3} alt="logo3" />
                </div>
                <div className="card-text">
                  <h2>Real-time Insights</h2>
                  <p>
                    Access powerful analytics and real-time data, enabling
                    informed decision-making and proactive management of quality
                    processes.
                  </p>
                </div>
                <div className="image2">
                  <img src={image4} alt="image4" />
                </div>
              </div>
              <div className="about-us_container_QMS-section_content_card-section_card">
                <div className="image1">
                  <img src={image3} alt="image3" />
                </div>
                <div className="card-logo">
                  <img src={logo4} alt="logo4" />
                </div>
                <div className="card-text">
                  <h2>User-Centric Interface</h2>
                  <p>
                    Intuitive interfaces and user-friendly designs ensure quick
                    adoption and minimal training requirements for your team.
                  </p>
                </div>
                <div className="image2">
                  <img src={image4} alt="image4" />
                </div>
              </div>
              <div className="about-us_container_QMS-section_content_card-section_card">
                <div className="image1">
                  <img src={image3} alt="image3" />
                </div>
                <div className="card-logo">
                  <img src={logo5} alt="logo5" />
                </div>
                <div className="card-text">
                  <h2>Continuous Innovation</h2>
                  <p>
                    We remain at the forefront of technological advancements,
                    consistently updating and improving our software to meet the
                    evolving needs of the pharmaceutical industry.
                  </p>
                </div>
                <div className="image2">
                  <img src={image4} alt="image4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us_container_banner-astakenis">
          <div
            className="about-us_container_banner-astakenis_banner-image"
            style={{
              backgroundImage: `url(${abtus3})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div className="about-us_container_banner-astakenis_banner-image_banner-content">
              <h1>
                At <span>Astakenis</span>
              </h1>
              <p>
                We are not just a software provider; we are your strategic
                partner in driving quality excellence and regulatory compliance.
                Our dedication to innovation, reliability, and customer
                satisfaction remains unwavering as we continue to empower
                pharmaceutical companies to achieve operational excellence
                through our state-of-the-art Quality Management Software
                solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="about-us_container_banner-joinUs">
          <div
            className="about-us_container_banner-joinUs_banner-image"
            style={{
              backgroundImage: `url(${banner2})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div className="about-us_container_banner-joinUs_banner-image_banner-content">
              <h1>Join with us</h1>
              <p>
                Join us on the journey toward streamlined operations, enhanced
                quality, and sustained compliance in the dynamic healthcare
                landscape. Get in touch with us today to explore how OneQMS can
                elevate your quality management processes to the next level.
              </p>
              <button onClick={handleJoinUs}>Join Us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
