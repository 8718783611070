import React, { useState } from "react";
import "./Header.scss";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram-img.png";
import logo1 from "../../assets/images/Astakenis Main Logo.png";
import { NavLink } from "react-router-dom";
import linkedin from "../../assets/images/linked_in.png";
import youtube from "../../assets/images/youtube.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="header">
      <div className="header_container">
        <div className="header_content">
          <div className="header_content_right">
            <div className="header_content_right_icons">
              <a
                href="https://www.linkedin.com/company/77741703/admin/feed/posts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="x" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555848406435"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="fb" />
              </a>

              <a
                href="https://www.instagram.com/astakenis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="insta" />
              </a>
              <a
                href="http://www.youtube.com/@AstakenisConsulting"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="youTube" />
              </a>
            </div>
          </div>
        </div>
        <div className="header_section">
          <div className="header_section_left">
            <a href="/">
              <img src={logo1} alt="logo" />
            </a>
          </div>
          <div className="header_section_right">
            <ul>
              <li>
                <NavLink
                  to="/"
                  activeclassname="active"
                  style={{ textDecoration: "none", color: "#07426a" }}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  activeclassname="active"
                  style={{ textDecoration: "none", color: "#07426a" }}
                >
                  About Us
                </NavLink>
              </li>
              <li className="dropdown">
                <div
                  activeclassname="active"
                  style={{ textDecoration: "none", color: "#07426a" }}
                >
                  Products
                  <i
                    className="fa fa-angle-down"
                    aria-hidden="true"
                    onClick={toggleDropdown}
                    style={{ marginLeft: "0.2vw" }}
                  ></i>
                </div>
                <div className="dropdown-content">
                  <NavLink to="/one-qms">OneQMS</NavLink>
                  <NavLink to="/quality-management-system">Quality Management</NavLink>
                  <NavLink to="/document-management-system">
                    Document Management
                  </NavLink>
                  <NavLink to="/training-management-system">
                    Training Management
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink
                  to="/events"
                  activeclassname="active"
                  style={{ textDecoration: "none", color: "#07426a" }}
                >
                  Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  activeclassname="active"
                  style={{ textDecoration: "none", color: "#07426a" }}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="header_mobile">
        <div className="header_mobile_container">
          <div className="header_mobile_section">
            <div className="header_mobile_section_left">
              <img src={logo1} alt="logo" />
            </div>
            <div className="header_mobile_section_right">
              <div className="header_mobile_section_right_drop">
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  onClick={handleOpen}
                  style={{ fontSize: "6vw" }}
                />
                {open && (
                  <div className="header_mobile_section_content">
                    <ul onClick={handleOpen}>
                      <li>
                        <NavLink
                          to="/"
                          activeclassname="active"
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/about"
                          activeclassname="active"
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <div onClick={(e) => e.stopPropagation()}>
                          <NavLink
                            to="/quality-management-system"
                            // activeclassname="active"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              marginRight: "1vw",
                            }}
                            onClick={toggleDropdown}
                          >
                            Products
                          </NavLink>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                            onClick={toggleDropdown}
                          ></i>
                          {isDropdownOpen && (
                            <ul onClick={handleOpen}>
                              <li>
                                <NavLink
                                  to="/one-qms"
                                  style={{
                                    textDecoration: "none",
                                    color: "#a4a4a4",
                                    fontSize: "12px",
                                  }}
                                >
                                  OneQMS
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/quality-management-system"
                                  style={{
                                    textDecoration: "none",
                                    color: "#a4a4a4",
                                    fontSize: "12px",
                                  }}
                                >
                                  Quality Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/document-management-system"
                                  style={{
                                    textDecoration: "none",
                                    color: "#a4a4a4",
                                    fontSize: "12px",
                                  }}
                                >
                                  Document Management
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/training-management-system"
                                  style={{
                                    textDecoration: "none",
                                    color: "#a4a4a4",
                                    fontSize: "12px",
                                  }}
                                >
                                  Training Management
                                </NavLink>
                              </li>
                            </ul>
                          )}
                        </div>
                      </li>
                      <li>
                        <NavLink
                          to="/events"
                          activeclassname="active"
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          Events
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/contact-us"
                          activeclassname="active"
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
