import './App.css';
import RouterComopnent from './App/RouterComponent/RouterComopnent';

function App() {
  return (
    <RouterComopnent />
  );
}

export default App;
