import React from "react";
import "./Footer.scss";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linked_in.png";
import youtube from "../../assets/images/youtube.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_container_section">
          <div className="footer_container_section_section1">
            <div className="footer_container_section_section1_list">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a href="/one-qms">Products</a>
                </li>
                <li>
                  <a href="/events">Events</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_container_section_section3">
            <div className="footer_container_section_section3_heading">
              <h3>India</h3>
            </div>
            <div className="footer_container_section_section3_content">
              <p>
                Astakenis Consulting Private Limited,
                <br />
                C-304, KSSIDC Complex 1st <br />
                Block - 2nd Floor, Electronic City <br />
                Phase 1, Bengaluru, Karnataka 560100.
                <br /> Phone: <span>+91 6360674997</span>
                <br /> Email:{" "}
                <a href="mailto:info@astakenis.com">info@astakenis.com</a>
              </p>
            </div>
          </div>
          <div className="footer_container_section_section2">
            <div className="footer_container_section_section2_heading">
              <h3>USA</h3>
            </div>
            <div className="footer_container_section_section2_content">
              <p>
                Astakenis Consulting Private Limited,
                <br /> 183 Broadway, Suite 210,
                <br /> Hicksville, NewYork
                <br /> 11801, USA.
                <br />
                Email:{" "}
                <a href="mailto:info@astakenis.com">info@astakenis.com</a>
              </p>
            </div>
          </div>
          <div className="footer_container_section_section2">
            <div className="footer_container_section_section2_heading">
              <h3>Europe</h3>
            </div>
            <div className="footer_container_section_section2_content">
              <p>
                Astakenis Consulting Private Limited,
                <br /> Regus Tetra Hoofddorp,
                <br /> Siriusdreef 17-27, 2132
                <br /> WT Hoofddorp, The Netherlands.
                <br />
                Email:{" "}
                <a href="mailto:info@astakenis.com">info@astakenis.com</a>
              </p>
            </div>
          </div>
          <div className="footer_container_section_section2">
            <div className="footer_container_section_section2_heading">
              <h3>United Kingdom</h3>
            </div>
            <div className="footer_container_section_section2_content">
              <p>
                Astakenis Consulting Private Limited, <br />
                27A, Green Lane,
                <br /> Northwood, Middlesex, <br />
                HA6 2PX, United Kingdom.
                <br />
                Email:{" "}
                <a href="mailto:info@astakenis.com">info@astakenis.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="footer_container_logos">
          <div className="footer_container_logos_images">
            <a
              href="https://www.linkedin.com/company/77741703/admin/feed/posts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="x" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61555848406435"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="fb" />
            </a>

            <a
              href="https://www.instagram.com/astakenis/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="insta" />
            </a>
            <a
              href="http://www.youtube.com/@AstakenisConsulting"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="x" />
            </a>
          </div>
          <div className="footer_container_logos_copyright-section">
            <p>
              &copy; Immigration All rights. <span>copyright 2023</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
